export const VehicleModelConfig = {
  api: {
    // get
    index: (manufacturerId) =>
      `/dashboard/marketplace/vehicle-model-list/?lock_manufacturer=${manufacturerId}`,
    // post
    create: () => '/dashboard/marketplace/vehicle-model/',
    // patch
    update: (manufacturerId) =>
      `/dashboard/marketplace/vehicle-model/${manufacturerId}/`,
  },
  events: {
    name: 'vehicle-model',
    // refresh-index-data
    refresh: `rid-vehicle-model`,
    // slideover-right
    sorId: 'vehicle-model',
    sorOpen: 'sor-open-vehicle-model',
    sorClose: 'sor-close-vehicle-model',
    sorToggle: 'sor-toggle-vehicle-model',
    // editing-data
    editingData: 'edit-vehicle-model-data',
    // viewing-data
    viewingData: 'view-vehicle-model-data',
  },
}
