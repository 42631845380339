export const VehicleManufactureConfig = {
  api: {
    // get
    index: () => '/dashboard/lock-manufacturers/',
    // post
    create: () => '/dashboard/lock-manufacturers/',
    // patch
    update: (manufacturerId) =>
      `/dashboard/lock-manufacturers/${manufacturerId}/`,
  },
  events: {
    name: 'vehicle-manufacture',
    // refresh-index-data
    refresh: `rid-vehicle-manufacture`,
    // slideover-right
    sorId: 'vehicle-manufacture',
    sorOpen: 'sor-open-vehicle-manufacture',
    sorClose: 'sor-close-vehicle-manufacture',
    sorToggle: 'sor-toggle-vehicle-manufacture',
    // editing-data
    editingData: 'edit-vehicle-manufacture-data',
    // viewing-data
    viewingData: 'view-vehicle-manufacture-data',
  },
}
