<template>
  <slide-over-right
    :id="sorId"
    editType="vehicle-model"
    v-on:showModal="showModal"
  >
    <loading :active.sync="isLoading"></loading>

    <div class="flex flex-wrap px-6 pt-12 w-full">
      <div class="items-center  panel-title header-title">
        {{ titleText }}
      </div>
    </div>

    <div class="px-6">
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(confirmSubmit)"
          enctype="multipart/form-data"
        >
          <UploadAvatar
            v-model="form.vehicle_image"
            :current="form.vehicle_image"
            :class="`mt-5`"
          />
          <div class="flex items-center pt-8">
            <div class="pr-2 w-1/2">
              <text-input
                rules="required"
                label="Model Name"
                placeholder="e.g. Segway MAX Plus"
                v-model="form.vehicle_model_name"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                name="IOT Protocol"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="IOT Protocol*" />
                <t-rich-select
                  placeholder="e.g. Omni Bike"
                  :options="iotProtocols"
                  valueAttribute="value"
                  textAttribute="display_name"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.iot_protocol"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules="required"
                label="Lifetime"
                type="number"
                placeholder="e.g. 10"
                v-model="form.vehicle_life_time"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                name="Swappable Battery Status"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label
                  :error="errors[0]"
                  text="Swappable Battery Status*"
                />
                <t-rich-select
                  placeholder="e.g. True"
                  :options="swapOptions"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.sweapeable_battery"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules="required"
                label="Max Speed"
                type="number"
                placeholder="e.g. 25"
                v-model="form.max_speed"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                name="Max Speed Unit"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Max Speed Unit*" />
                <t-rich-select
                  placeholder="e.g. kmph"
                  :options="speedUnitOptions"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.max_speed_unit"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules="required"
                label="Mileage"
                type="number"
                placeholder="e.g. 100"
                v-model="form.mileage"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                name="Mileage Unit"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Mileage Unit *" />
                <t-rich-select
                  placeholder="e.g. Mile"
                  :options="mileageOptions"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.mileage_unit"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules="required"
                label="Youtube URL"
                type="text"
                placeholder="e.g. http://www.website.com/id"
                v-model="form.vehicle_website_url"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules="required"
                label="Website URL"
                type="text"
                placeholder="e.g. http://www.youtube.com/id"
                v-model="form.vehicle_youtube_url"
              />
            </div>
          </div>

          <button type="submit" ref="submitButton" class="hidden">Save</button>
        </form>
      </ValidationObserver>
      <div class="flex justify-end items-center pr-2 mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">Cancel</anchor-button>
        </slide-over-right-trigger>
        <t-button type="submit" @click="submit()" class="ml-3">Save</t-button>
      </div>
    </div>
  </slide-over-right>
</template>
<script>
import { VehicleModelConfig } from '@/config/VehicleModelConfig'
import { EventBus } from '@/utils/EventBus'
import xMan from '@/utils/xMan'
import SlideOverRight from '@/components/modals/SlideOverRight'
import TextInput from '@/components/form/TextInput'
import InputLabel from '@/components/form/InputLabel'
import AnchorButton from '@/components/form/AnchorButton'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import { UploadAvatar } from '@/components/form'
export default {
  name: 'VehicleModelAddEdit',
  components: {
    SlideOverRight,
    TextInput,
    InputLabel,
    AnchorButton,
    SlideOverRightTrigger,
    UploadAvatar,
  },
  data() {
    return {
      sorId: VehicleModelConfig.events.sorId,
      isLoading: false,
      form: {
        vehicle_model_name: '',
        lock_manufacturer: '',
        iot_protocol: '',
        vehicle_life_time: null,
        vehicle_youtube_url: '',
        vehicle_website_url: '',
        max_speed: '',
        max_speed_unit: '',
        mileage: '',
        mileage_unit: '',
        sweapeable_battery: false,
        vehicle_image: null,
      },

      item: {},
      iotProtocols: [],
      lockManufacturerId: '',
      swapOptions: [
        { text: 'True', value: true },
        { text: 'False', value: false },
      ],
      mileageOptions: [
        { text: 'Kilometer', value: 'Kilometer' },
        { text: 'Mile', value: 'Mile' },
      ],
      speedUnitOptions: [
        { text: 'kmph', value: 'kmph' },
        { text: 'mph', value: 'mph' },
      ],
    }
  },
  computed: {
    isEditing: function() {
      // enabled status-switch by default
      return Object.keys(this.item).length > 1
    },
    titleText() {
      return this.isEditing ? 'Edit Vehicle Model' : 'Add Vehicle Model'
    },
  },
  mounted() {
    EventBus.$on(VehicleModelConfig.events.editingData, (data) => {
      this.init()
      this.item = data.item
      this.iotProtocols = data.iotProtocols

      if (Object.keys(this.item).length > 1) {
        //edit
        this.form.vehicle_model_name = data.item.vehicle_model_name
        this.form.lock_manufacturer = data.item.lock_manufacturer
        this.form.iot_protocol = data.item.iot_protocol
        this.form.vehicle_life_time = data.item.vehicle_life_time
        this.form.vehicle_youtube_url = data.item.vehicle_youtube_url
        this.form.vehicle_website_url = data.item.vehicle_website_url
        this.form.vehicle_website_url = data.item.vehicle_website_url
        this.form.max_speed = data.item.max_speed
        this.form.max_speed_unit = data.item.max_speed_unit
        this.form.mileage = data.item.mileage
        this.form.mileage_unit = data.item.mileage_unit
        this.form.sweapeable_battery = data.item.sweapeable_battery
        this.form.vehicle_image = data.item.vehicle_image
      } else {
        //add
        this.init(data.lockManufacturerId)
      }
    })
  },
  methods: {
    showModal() {
      dispatchEvent(new Event(VehicleModelConfig.events.sorToggle))
    },
    init(lockManufacturerId) {
      this.form.vehicle_model_name = ''
      this.form.lock_manufacturer = lockManufacturerId
      this.form.iot_protocol = ''
      this.form.vehicle_life_time = null
      this.form.vehicle_youtube_url = ''
      this.form.vehicle_website_url = ''
      this.form.max_speed = ''
      this.form.max_speed_unit = ''
      this.form.mileage = ''
      this.form.mileage_unit = ''
      this.form.sweapeable_battery = false
      this.form.vehicle_image = null
    },
    submit() {
      this.$refs.submitButton.click()
    },
    async confirmSubmit() {
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? VehicleModelConfig.api.update(this.item.id)
        : VehicleModelConfig.api.create()

      //   const jsonString_vehicle_classes = JSON.stringify(
      //     this.form.vehicle_classes
      //   )
      const formProxy = {
        ...this.form,
      }
      if (
        typeof this.form.vehicle_image === 'string' ||
        this.form.vehicle_image == null
      ) {
        delete formProxy.vehicle_image
      }
      let data = new xMan(formProxy).toFormData()
      let message = this.isEditing
        ? 'Vehicle Model updated successfully'
        : 'Vehicle Model added successfully'

      this.isLoading = true

      try {
        let response = await this.$http({
          method,
          url,
          data,
        })

        console.log('response = ', url, '  ', method, '  ')
        console.log('data = ', response)

        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(VehicleModelConfig.events.sorToggle))
        dispatchEvent(new Event(VehicleModelConfig.events.refresh))
        this.$emit('add-edit')
        // Reset the form
        this.init()
        this.$notify({
          group: 'bottomLeft',
          type: 'success',
          title: 'Success',
          text: message,
        })
        // Resolved
        return response.status
      } catch (error) {
        const e = error.response
        console.log('err', e)

        this.$notify(
          {
            group: 'bottomLeft',
            type: 'error',
            title: `Error Occured [Code: ${e.status}]`,
            text: e.data ?? 'The action could not be executed',
          },
          5000
        )

        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss">
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
</style>
