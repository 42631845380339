var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slide-over-right',{attrs:{"id":_vm.sorId,"editType":"vehicle-model"},on:{"showModal":_vm.showModal}},[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"flex flex-wrap px-6 pt-12 w-full"},[_c('div',{staticClass:"items-center  panel-title header-title"},[_vm._v(" "+_vm._s(_vm.titleText)+" ")])]),_c('div',{staticClass:"px-6"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmSubmit)}}},[_c('UploadAvatar',{class:"mt-5",attrs:{"current":_vm.form.vehicle_image},model:{value:(_vm.form.vehicle_image),callback:function ($$v) {_vm.$set(_vm.form, "vehicle_image", $$v)},expression:"form.vehicle_image"}}),_c('div',{staticClass:"flex items-center pt-8"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('text-input',{attrs:{"rules":"required","label":"Model Name","placeholder":"e.g. Segway MAX Plus"},model:{value:(_vm.form.vehicle_model_name),callback:function ($$v) {_vm.$set(_vm.form, "vehicle_model_name", $$v)},expression:"form.vehicle_model_name"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('ValidationProvider',{attrs:{"name":"IOT Protocol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"IOT Protocol*"}}),_c('t-rich-select',{attrs:{"placeholder":"e.g. Omni Bike","options":_vm.iotProtocols,"valueAttribute":"value","textAttribute":"display_name","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.iot_protocol),callback:function ($$v) {_vm.$set(_vm.form, "iot_protocol", $$v)},expression:"form.iot_protocol"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('text-input',{attrs:{"rules":"required","label":"Lifetime","type":"number","placeholder":"e.g. 10"},model:{value:(_vm.form.vehicle_life_time),callback:function ($$v) {_vm.$set(_vm.form, "vehicle_life_time", $$v)},expression:"form.vehicle_life_time"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Swappable Battery Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"Swappable Battery Status*"}}),_c('t-rich-select',{attrs:{"placeholder":"e.g. True","options":_vm.swapOptions,"valueAttribute":"value","textAttribute":"text","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.sweapeable_battery),callback:function ($$v) {_vm.$set(_vm.form, "sweapeable_battery", $$v)},expression:"form.sweapeable_battery"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('text-input',{attrs:{"rules":"required","label":"Max Speed","type":"number","placeholder":"e.g. 25"},model:{value:(_vm.form.max_speed),callback:function ($$v) {_vm.$set(_vm.form, "max_speed", $$v)},expression:"form.max_speed"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Max Speed Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"Max Speed Unit*"}}),_c('t-rich-select',{attrs:{"placeholder":"e.g. kmph","options":_vm.speedUnitOptions,"valueAttribute":"value","textAttribute":"text","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.max_speed_unit),callback:function ($$v) {_vm.$set(_vm.form, "max_speed_unit", $$v)},expression:"form.max_speed_unit"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('text-input',{attrs:{"rules":"required","label":"Mileage","type":"number","placeholder":"e.g. 100"},model:{value:(_vm.form.mileage),callback:function ($$v) {_vm.$set(_vm.form, "mileage", $$v)},expression:"form.mileage"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('ValidationProvider',{attrs:{"name":"Mileage Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"Mileage Unit *"}}),_c('t-rich-select',{attrs:{"placeholder":"e.g. Mile","options":_vm.mileageOptions,"valueAttribute":"value","textAttribute":"text","hideSearchBox":true,"variant":{ danger: !!errors[0] }},model:{value:(_vm.form.mileage_unit),callback:function ($$v) {_vm.$set(_vm.form, "mileage_unit", $$v)},expression:"form.mileage_unit"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"w-full"},[_c('text-input',{attrs:{"rules":"required","label":"Youtube URL","type":"text","placeholder":"e.g. http://www.website.com/id"},model:{value:(_vm.form.vehicle_website_url),callback:function ($$v) {_vm.$set(_vm.form, "vehicle_website_url", $$v)},expression:"form.vehicle_website_url"}})],1)]),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"w-full"},[_c('text-input',{attrs:{"rules":"required","label":"Website URL","type":"text","placeholder":"e.g. http://www.youtube.com/id"},model:{value:(_vm.form.vehicle_youtube_url),callback:function ($$v) {_vm.$set(_vm.form, "vehicle_youtube_url", $$v)},expression:"form.vehicle_youtube_url"}})],1)]),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v("Save")])],1)]}}])}),_c('div',{staticClass:"flex justify-end items-center pr-2 mt-6"},[_c('slide-over-right-trigger',{attrs:{"id":_vm.sorId}},[_c('anchor-button',{attrs:{"variant":"secondary"}},[_vm._v("Cancel")])],1),_c('t-button',{staticClass:"ml-3",attrs:{"type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }