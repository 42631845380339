<template lang="">
  <base-layout>
    <ManufactureVehicleModels />
  </base-layout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ManufactureVehicleModels from '@/views/vehicle-manufacturers/ManufactureVehicleModels.vue'
export default {
  name: 'VehicleManufacturersDetails',
  components: {
    BaseLayout,
    ManufactureVehicleModels,
  },
}
</script>
<style lang=""></style>
