<template lang="">
  <div>
    <VehicleModelAddEdit @add-edit="$store.dispatch('fsTable/fetchData')" />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus title="Vehicle Model" @plus="add" :hide-plus="false" />
        </div>
        <div class="summary-card-container">
          <SummaryCard
            title="Total "
            :value="indexMetaData.count.total"
            variant="purple"
            size="large"
          />
        </div>
      </div>

      <FSTable
        :fst-id="`vehicleModelIndex`"
        :searchEnabled="false"
        :headers="tableHeaders"
        :endpoint="indexDataEndpoint"
        :qso="{ append: '', prepend: '&' }"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            text-fallback-always
          >
            <FSTableRowItem :text="item.vehicle_model_name" />
            <FSTableRowItem :text="getProtocolName(item.iot_protocol)" />
            <FSTableRowItem
              :text="`${item.max_speed} ${item.max_speed_unit}`"
            />
            <FSTableRowItem :text="`${item.mileage} ${item.mileage_unit}`" />
            <FSTableRowItem
              ><div class="flex items-center">
                <oto-edit-icon @click="edit(item)" /></div
            ></FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
  </div>
</template>
<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import VehicleModelAddEdit from '@/views/vehicle-manufacturers/VehicleModelAddEdit.vue'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import { VehicleModelConfig } from '@/config/VehicleModelConfig'
import { EventBus } from '@/utils'
import { useEndpoints } from '@/composables'
import SummaryCard from '@/components/cards/SummaryCard'
export default {
  name: 'ManufactureVehicleModels',
  components: {
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    VehicleModelAddEdit,
    SummaryCard,
  },
  data() {
    return {
      indexMetaData: {
        count: {
          total: 0,
        },
      },
      indexDataEndpoint: VehicleModelConfig.api.index(this.$route.params.id),

      tableHeaders: [
        { text: 'Name', width: '30%', sort: 'null' },
        { text: 'Protocol', width: '30%', sort: 'null' },
        { text: 'Max Speed', width: '20%', sort: 'null' },
        { text: 'Mileage', width: '20%', sort: 'null' },
        { text: 'Actions', width: '10%', sort: 'null' },
      ],
      iotProtocols: [],
    }
  },
  async created() {
    this.iotProtocols = await this.$http
      .get(useEndpoints.dropdown.lockTypes())
      .then((res) => res.data.data)
      .catch((err) => console.log('lockTypesErr = ', err.response))
  },
  computed: {
    lockManufacturerId() {
      return this.$route.params.id
    },
  },
  methods: {
    add() {
      EventBus.$emit(VehicleModelConfig.events.editingData, {
        iotProtocols: this.iotProtocols,
        lockManufacturerId: this.lockManufacturerId,
        item: {},
      })
      dispatchEvent(new Event(VehicleModelConfig.events.sorToggle))
    },
    edit(item) {
      console.log(item)
      EventBus.$emit(VehicleModelConfig.events.editingData, {
        item: item,
        iotProtocols: this.iotProtocols,
      })
      dispatchEvent(new Event(VehicleModelConfig.events.sorToggle))
    },
    getProtocolName(protocolId) {
      const selectedProtocols = this.iotProtocols.find(
        (item) => item.value === protocolId
      )

      return selectedProtocols.display_name
    },
  },
}
</script>
<style lang=""></style>
