<template>
  <slide-over-right
    :id="sorId"
    editType="vehicle-manufacture"
    v-on:showModal="showModal"
  >
    <loading :active.sync="isLoading"></loading>

    <div class="flex flex-wrap px-6 pt-12 w-full">
      <div class="items-center  panel-title header-title">
        {{ titleText }}
      </div>
    </div>

    <div class="px-6">
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(confirmSubmit)"
          enctype="multipart/form-data"
        >
          <div class="flex items-center pt-8">
            <div class="pr-2 w-1/2">
              <text-input
                rules="required"
                label="Name"
                placeholder="e.g. Segway"
                v-model="form.name"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                name="Origin Country"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Origin Country *" />
                <t-rich-select
                  placeholder="e.g. USA"
                  :options="countries"
                  valueAttribute="id"
                  textAttribute="name"
                  :hideSearchBox="false"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.country.id"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <ValidationProvider
                name="Vehicle Class"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Vehicle Class *" />
                <t-rich-select
                  placeholder="Select Vehicle Class"
                  :options="vehicleClasses"
                  valueAttribute="value"
                  textAttribute="text"
                  :close-on-select="false"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.vehicle_classes"
                  multiple
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules="required"
                label="Founded Date"
                placeholder=""
                type="date"
                v-model="form.year_founded"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                name="Vehicle Category"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Vehicle Category *" />
                <t-rich-select
                  placeholder="Select Vehicle Category"
                  :options="vehicleCategories"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.vehicle_category"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <ValidationProvider
                name="Business Type"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Business Type *" />
                <t-rich-select
                  placeholder="Select Business Type"
                  :options="businessType"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.vehicle_manufacturer_business_type"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
            <div class="pl-2 w-1/2">
              <text-input
                rules="required"
                label="Business ID"
                placeholder="Write Business id"
                v-model="form.vehicle_manufacturer_business_id"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <ValidationProvider
                name="Employee Size"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Employee Size *" />
                <t-rich-select
                  placeholder="Select Employee Size"
                  :options="employeeSizes"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.company_employee_size"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules=""
                label="Website URL"
                placeholder="https://www.example.com/id"
                v-model="form.website_url"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules=""
                label="LinkedIn URL"
                placeholder="https://www.linkedin.com/id"
                v-model="form.linkedin_url"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules=""
                label="Facebook URL"
                placeholder="https://www.facebook.com/id"
                v-model="form.facebook_url"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules=""
                label="Twitter URL"
                placeholder="https://www.twitter.com/id"
                v-model="form.twitter_url"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules=""
                label="Instagram URL"
                placeholder="https://www.instagram.com/id"
                v-model="form.instagram_url"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules=""
                label="Youtube URL"
                placeholder="https://www.youtube.com/id"
                v-model="form.youtube_url"
              />
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="w-full">
              <AppInput
                v-model="form.headquarter_address"
                type="textarea"
                name="Headquarter Address"
                label="Headquarter Address"
                rules=""
                placeholder="Write Headquarter Address"
              />
            </div>
          </div>

          <button type="submit" ref="submitButton" class="hidden">Save</button>
        </form>
      </ValidationObserver>
      <div class="flex justify-end items-center pr-2 mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">Cancel</anchor-button>
        </slide-over-right-trigger>
        <t-button type="submit" @click="submit()" class="ml-3">Save</t-button>
      </div>
    </div>
  </slide-over-right>
</template>
<script>
import { VehicleManufactureConfig } from '@/config/VehicleManufactureConfig'
import { EventBus } from '@/utils/EventBus'
import xMan from '@/utils/xMan'
import SlideOverRight from '@/components/modals/SlideOverRight'
import TextInput from '@/components/form/TextInput'
import InputLabel from '@/components/form/InputLabel'
import AnchorButton from '@/components/form/AnchorButton'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
export default {
  name: 'AddEditVehicleManufacture',
  components: {
    SlideOverRight,
    TextInput,
    InputLabel,
    AnchorButton,
    SlideOverRightTrigger,
  },
  data() {
    return {
      sorId: VehicleManufactureConfig.events.sorId,
      isLoading: false,
      form: {
        name: '',
        country: {
          id: '',
        },
        vehicle_category: '',
        vehicle_classes: [],
        vehicle_manufacturer_business_type: '',
        vehicle_manufacturer_business_id: '',
        year_founded: '',
        company_employee_size: '',
        website_url: '',
        linkedin_url: '',
        facebook_url: '',
        twitter_url: '',
        instagram_url: '',
        youtube_url: '',
        headquarter_address: '',
      },
      item: {},
      employeeSizes: [
        {
          text: '1-10',
          value: '1-10',
        },
        {
          text: '11-50',
          value: '11-50',
        },
        {
          text: '51-100',
          value: '51-100',
        },
        {
          text: '101-250',
          value: '101-250',
        },
        {
          text: '251-500',
          value: '251-500',
        },
        {
          text: '501-1000',
          value: '501-1000',
        },
        {
          text: '1001-2500',
          value: '1001-2500',
        },
        {
          text: '2501-5000',
          value: '2501-5000',
        },
        {
          text: '5001-10000',
          value: '5001-10000',
        },
        {
          text: '10000+',
          value: '10000+',
        },
      ],
      businessType: [
        {
          text: 'Private',
          value: 'Private',
        },
        {
          text: 'Public',
          value: 'Public',
        },
      ],
      vehicleCategories: [
        {
          text: 'Shared Mobility',
          value: 'SM',
        },
        {
          text: 'Store Rental',
          value: 'SR',
        },
      ],
      vehicleClasses: [
        {
          text: 'SCOOTER',
          value: 'SCOOTER',
        },
        {
          text: 'SCOOTER PRO',
          value: 'SCOOTER PRO',
        },
        {
          text: 'EBIKE',
          value: 'EBIKE',
        },
        {
          text: 'BIKE',
          value: 'BIKE',
        },
        {
          text: 'COCO',
          value: 'COCO',
        },
        {
          text: 'MOPEDS',
          value: 'MOPEDS',
        },
      ],
      countries: [],
    }
  },
  computed: {
    isEditing: function() {
      // enabled status-switch by default
      return Object.keys(this.item).length > 1
    },
    titleText() {
      return this.isEditing
        ? 'Edit Vehicle Manufacturer'
        : 'Add Vehicle Manufacturer'
    },
  },
  mounted() {
    EventBus.$on(VehicleManufactureConfig.events.editingData, (data) => {
      this.init()
      this.item = data.item
      this.countries = data.countries
      if (Object.keys(this.item).length > 1) {
        //edit
        this.form.name = data.item.name
        this.form.country.id = data.item?.country?.id || ''
        this.form.vehicle_category = data.item.vehicle_category
        this.form.vehicle_classes = data.item.vehicle_classes
        this.form.vehicle_manufacturer_business_type =
          data.item.vehicle_manufacturer_business_type
        this.form.vehicle_manufacturer_business_id =
          data.item.vehicle_manufacturer_business_id
        this.form.year_founded = data.item.year_founded
        this.form.company_employee_size = data.item.company_employee_size
        this.form.website_url = data.item.website_url
        this.form.linkedin_url = data.item.linkedin_url
        this.form.facebook_url = data.item.facebook_url
        this.form.twitter_url = data.item.twitter_url
        this.form.instagram_url = data.item.instagram_url
        this.form.youtube_url = data.item.youtube_url
        this.form.headquarter_address = data.item.headquarter_address
      } else {
        //add
        this.init()
      }
    })
  },
  methods: {
    showModal() {
      dispatchEvent(new Event(VehicleManufactureConfig.events.sorToggle))
    },
    init() {
      this.form.name = ''
      this.form.country.id = ''
      this.form.vehicle_category = ''
      this.form.vehicle_classes = []
      this.form.vehicle_manufacturer_business_type = ''
      this.form.vehicle_manufacturer_business_id = ''
      this.form.year_founded = ''
      this.form.company_employee_size = ''
      this.form.website_url = ''
      this.form.linkedin_url = ''
      this.form.facebook_url = ''
      this.form.twitter_url = ''
      this.form.instagram_url = ''
      this.form.youtube_url = ''
      this.form.headquarter_address = ''
    },
    submit() {
      this.$refs.submitButton.click()
    },
    async confirmSubmit() {
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? VehicleManufactureConfig.api.update(this.item.id)
        : VehicleManufactureConfig.api.create()

      const jsonString_vehicle_classes = JSON.stringify(
        this.form.vehicle_classes
      )
      const formProxy = {
        ...this.form,
        country: this.form.country.id,
        vehicle_classes: jsonString_vehicle_classes,
      }
      let data = new xMan(formProxy).toFormData()
      let message = this.isEditing
        ? 'Vehicle Manufacture updated successfully'
        : 'Vehicle Manufacture added successfully'

      this.isLoading = true

      try {
        let response = await this.$http({
          method,
          url,
          data,
        })

        console.log('response = ', url, '  ', method, '  ')
        console.log('data = ', response)

        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(VehicleManufactureConfig.events.sorToggle))
        dispatchEvent(new Event(VehicleManufactureConfig.events.refresh))
        this.$emit('add-edit')
        // Reset the form
        this.init()
        this.$notify({
          group: 'bottomLeft',
          type: 'success',
          title: 'Success',
          text: message,
        })
        // Resolved
        return response.status
      } catch (error) {
        const e = error.response
        console.log('err', e)

        this.$notify(
          {
            group: 'bottomLeft',
            type: 'error',
            title: `Error Occured [Code: ${e.status}]`,
            text: e.data ?? 'The action could not be executed',
          },
          5000
        )

        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss">
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
</style>
